<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn class="ma-2" dark color="danger" @click="nuevoRemolque">
                            <v-icon>mdi-plus</v-icon>
                           Nuevo Remolque
                        </v-btn>
                     <!--   <div>
                            <v-btn class="ma-2" color="primary">
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>
                            <v-btn outlined class="ma-2">Import</v-btn>
                            <v-btn outlined class="ma-2">Export</v-btn>
                        </div>-->
                    </div>
                </v-card-title>
                <v-card-title>
                   Remolques
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_remolques"
                    item-key="idremolque"
                   show-expand

                    class="elevation-1 table-one"
                    multi-sort
                >
               
 

                    <template v-slot:item.action="{item}">

                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                    @click="editremolque(item.idremolque)"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="danger"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="eliminarremolque(item)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.badge="{item}">
                      <!--  <template v-if="item.badge === 'Active'">
                            <v-chip
                                class=""
                                color="success"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-check</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip
                                class=""
                                color="danger"
                                label
                                small
                                text-color="white"
                            >
                                <v-icon small left>mdi-close</v-icon>
                                {{ item.badge }}
                            </v-chip>
                        </template>-->
                    </template>

                       <template v-slot:expanded-item="{ item }">
        <td :colspan="7">
        
        <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Tarjeta de Circulación</th>
                    <th>Tarjeta Físico-Mecánica</th>
                    <th>Póliza</th>
                    <th>Factura</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                       <a
                      style="cursor: pointer"
                      @click="ver_imagen(item.tarjeta_circulacion)"
                    >
                      {{ item.tarjeta_circulacion }}</a></td>
                   <td>
                     
                      <a
                      style="cursor: pointer"
                      @click="ver_imagen(item.tarjeta_fisico_mecanica)"
                    >
                     {{ item.tarjeta_fisico_mecanica }}</a></td>
                   
                   <td>
                      <a
                      style="cursor: pointer"
                      @click="ver_imagen(item.seguro_vehiculo)"
                    >
                     {{ item.seguro_vehiculo }}</a></td>
                   <td>
                      <a
                      style="cursor: pointer"
                      @click="ver_imagen(item.factura)"
                    >
                     {{ item.factura }}</a></td>

                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
      </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { url } from "../../main.js";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Tractocamiones'
    },
    data() {
    return {
          search: '',
           // selected: [],
            headers: [
                {
                    text: 'No. Eco',
                    align: 'start',
                    sortable: false,
                    value: 'no_economico'
                },
               
                {text: 'Marca', value: 'marca'},
                {text: 'Modelo', value: 'modelo'},
                {text: 'Año', value: 'anio'},
                {text: 'Placas', value: 'placas'},


                {text: 'Acción', value: 'action'}
            ],
     
      edit: false,
      permiso: 0,
     
      operador: {
        idoperador: 0,

        nombre: "",
        apellidos: "",
        fecha_licencia: '',
        fecha_vigencia: '',
        documentacion: {
          ine: null,
          licencia: null,
          examen_medico: null,
        },
      },
    
      lista_remolques: []

    };
  },
 
  mounted() {
   this.verremolques();
  },
  

  methods: {
    verremolques() {
      this.axios
        .get(url + "apiRemolques.php/remolques")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_remolques = respuesta.data;

          console.log(this.lista_remolques);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
  
    nuevoRemolque(){
       this.$router.push("/catalogos/remolque/0");


    },
  
  

   
    editremolque(idremolque) {
             this.$router.push("/catalogos/remolque/"+idremolque);

    },
   eliminarremolque(remolque) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar el remolque?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiRemolques.php/delete_remolque", remolque)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Remolque Eliminado");
                this.verremolques();
              }
            });
        }
      });
    },
    vertipos() {
      this.axios
        .get(url + "apiRemolques.php/tipos_remolque")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_tipos = respuesta.data;

          console.log(this.lista_tipos);
         
              this.remolque.idtipo_remolque = this.lista_tipos[0].idtipo_remolque;
          
         
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },

    getidoperador(e) {
      if (e.target.options.selectedIndex > -1) {
        this.operador.idoperador = this.lista_operadores[
          e.target.options.selectedIndex
        ].idoperador;
      }
    },
   
    ver_imagen(name) {
      this.axios
        .get(url + "apiOperadores.php/getImageDrive/" + name)
        .then((respuesta) => {
          console.log(respuesta);
          
          var image_data = respuesta.data;
          
          window.open(image_data.webViewLink);
        });
    },

   

    cancelarEdicion() {
      this.edit = false;
      this.limpiarcampos();
    },
  }
}
</script>
